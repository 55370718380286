<template>
  <b-modal :id="modalId" hide-footer centered>
    <template v-slot:modal-title>
      <div class="mr-3 cursor-pointer" @click="back">
        <font-awesome-icon icon="chevron-left" />
      </div>
      <strong>{{ modalTitle }}</strong>
    </template>
    <div class="d-block text-center">
      <div class="row">
        <div class="col-12 text-left">
          <div class="form-group">
            <label>{{ $t('attributes') }} {{ $t('name') }}</label>
            <input
              v-model="attribute.name"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <div>
              <strong>Please select a color</strong>
            </div>
            <v-swatches
              v-model="attribute.color"
              :swatches="colors"
              row-length="6"
              show-border
              inline
            />
          </div>
        </div>
      </div>
      <div class="mt-1 text-right">
        <b-button
          v-if="attribute.id"
          variant="danger"
          class="mx-1"
          @click="showAttributeDeleteModal"
        >
          {{ $t('delete') }}
        </b-button>
        <b-button
          class="mx-1"
          variant="secondary"
          @click="saveAttribute"
        >
          {{ $t('save') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import VSwatches from 'vue-swatches'

import AttributesMixin from '@/mixins/AttributesMixin'

import { colors } from '@/constants'

export default {
  name: 'UpsertAttributeModal',
  components: {
    VSwatches
  },
  mixins: [AttributesMixin],
  props: {
    selectedAttribute: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        name: '',
        color: '#61bd4f'
      })
    },
    organisation: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      required: false,
      default: 'Update attributes'
    }
  },
  data: () => ({
    modalId: 'add-new-attributes',
    attribute: {
      id: null,
      name: '',
      color: '#61bd4f'
    },
    colors
  }),
  computed: {
    ...mapGetters('attributes', [
      'getAttributeApiMessage',
      'getAttributeApiResponseCode'
    ])
  },
  watch: {
    selectedAttribute: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.attribute = val
        }
      }
    }
  },
  methods: {
    back() {
      this.hideModal(this.modalId)
      this.$emit('show-attributes-modal')
    },
    hideModal(modalId) {
      this.$bvModal.hide(modalId)
    },
    async saveAttribute() {
      if (!this.attribute || !this.attribute.name) {
        return
      }
      if (!this.attribute.id) {
        delete this.attribute.id
      }
      // eslint-disable-next-line no-unused-vars
      const [_, orgName, orgPath] = this.organisation.split('/')
      await this.upsertAttribute({
        id: this.attribute.id,
        name: this.attribute.name,
        color: this.attribute.color,
        org: orgName,
        orgPath: `/${orgPath}`
      }).then(async data => {
        if (this.getAttributeApiResponseCode === 200) {
          this.updateAlert('success', this.getAttributeApiMessage)
        } else if (this.getAttributeApiResponseCode === 409) {
          this.updateAlert('danger', this.$t('attributeErrorMessage'))
        } else {
          this.updateAlert('danger', this.getAttributeApiMessage)
        }
        await this.getAllAttributes()
        this.back()
        this.$emit('refresh-data')
      })
    },
    updateAlert(variant, text) {
      this.$emit('updateAlert', { variant, text })
    },
    showAttributeDeleteModal() {
      this.$emit('show-attribute-delete-modal')
      this.hideModal(this.modalId)
    }
  }
}
</script>

<style>
.modal-title {
  display: flex;
}
</style>
