<template>
  <b-modal :id="modalId" hide-footer centered>
    <template v-slot:modal-title>
      <div class="mr-3 cursor-pointer" @click="back">
        <font-awesome-icon icon="chevron-left" />
      </div>
      <strong>{{ modalTitle }}</strong>
    </template>
    <div class="d-block text-center">
      <div class="row">
        <div class="col-12 text-left">
          {{ $t('deleteConfirm') }}
        </div>
      </div>
      <div class="mt-1 text-right">
        <b-button
          variant="danger"
          class="mx-1"
          @click="deleteCurrentAttribute"
        >
          {{ $t('delete') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {  mapGetters } from 'vuex'
import AttributesMixin from '@/mixins/AttributesMixin'

export default {
  name: 'DeleteAttributeModal',
  mixins: [AttributesMixin],
  props: {
    selectedAttribute: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        name: '',
        color: '#61bd4f'
      })
    },
    modalTitle: {
      type: String,
      required: false,
      default: 'Delete attributes'
    }
  },
  data: () => ({
    modalId: 'delete-attribute',
    attribute: {
      id: null,
      name: '',
      color: '#61bd4f'
    }
  }),
  computed: {
    ...mapGetters('attributes', [
      'getAttributeApiMessage',
      'getAttributeApiResponseCode'
    ])
  },
  watch: {
    selectedAttribute: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.attribute = val
        }
      }
    }
  },
  methods: {
    hideModal(modalId) {
      this.$bvModal.hide(modalId)
    },
    async deleteCurrentAttribute() {
      if (!this.attribute || !this.attribute.name) {
        return
      }
      if (!this.attribute.id) {
        delete this.attribute.id
      }
      await this.deleteAttribute(this.attribute).then(async () => {
        this.hideModal(this.modalId)
        this.updateAlert(
          this.getAttributeApiResponseCode === 200
            ? 'success'
            : 'danger',
          this.getAttributeApiMessage
        )
        await this.getAllAttributes()
        this.$emit('show-attributes-modal')
        this.$emit('refresh-data')
      })
    },
    updateAlert(variant, text) {
      this.$emit('updateAlert', { variant, text })
    },
    back() {
      this.hideModal(this.modalId)
      this.$emit('show-attributes-modal')
    }
  }
}
</script>

<style></style>
