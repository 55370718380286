<template>
  <div>
    <b-modal :id="modalId" hide-footer centered size="sm">
      <template #modal-title>
        <strong>{{ modalTitle }}</strong>
      </template>
      <div class="row pb-2">
        <div class="col-12 attributes-container">
          <template v-if="!attributes.length">
            <div class="text-center">
              {{ $t('noAttributes') }}
            </div>
          </template>
          <template v-else>
            <div
              v-for="(attribute, index) in attributes"
              :key="`index-${index}`"
              class="my-1"
            >
              <div class="attribute-container">
                <div
                  :ref="`attribute-${attribute.id}`"
                  :style="{
                    backgroundColor: attribute.color
                  }"
                  class="attribute d-flex justify-content-between"
                  :class="[
                    attribute.selected
                      ? 'select-attribute'
                      : 'unselect-attribute'
                  ]"
                  @click="toggleAttribute(attribute)"
                >
                  <p>
                    {{ attribute.name }}
                  </p>
                  <span
                    v-if="attribute.selected"
                    class="float-right selected"
                  />
                </div>
                <div
                  class="pt-1 ml-1 cursor-pointer"
                  @click="showUpsertAttributeModal(attribute)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </div>
                <div
                  class="pt-1 ml-1 cursor-pointer"
                  @click="showDeleteModal(attribute)"
                >
                  <font-awesome-icon icon="trash" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <b-button
        variant="secondary"
        class="mt-2 float-right"
        @click="addNewAttribute"
      >
        {{ $t('addNewAttribute') }}
      </b-button>
    </b-modal>
    <upsert-attribute-modal
      :selected-attribute="attribute"
      :modal-title="title"
      :organisation="organisation"
      @show-attributes-modal="showModalAndResetState"
      @show-attribute-delete-modal="showDeleteModal"
      @updateAlert="showAlert"
      @refresh-data="$emit('refresh-data')"
    />
    <delete-attribute-modal
      :modal-title="modalTitle"
      :selected-attribute="attribute"
      @show-attributes-modal="showModalAndResetState"
      @updateAlert="showAlert"
      @refresh-data="$emit('refresh-data')"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AttributesMixin from '@/mixins/AttributesMixin'
import UpsertAttributeModal from './UpsertAttributeModal'
import DeleteAttributeModal from './DeleteAttributeModal'

export default {
  name: 'AttributesModal',
  components: {
    UpsertAttributeModal,
    DeleteAttributeModal
  },
  mixins: [AttributesMixin],
  props: {
    attributes: {
      type: Array,
      required: true,
      default: () => []
    },
    selectedValue: {
      type: Object,
      required: true,
      default: () => {}
    },
    modalTitle: {
      type: String,
      required: false,
      default: 'Attributes'
    }
  },
  data: () => ({
    modalId: 'modal-attributes',
    upsertAttributesModalId: 'add-new-attributes',
    deleteAttributesModalId: 'delete-attribute',
    title: 'Add new attributes',
    attribute: {
      id: null,
      name: '',
      color: '#61bd4f'
    }
  }),
  computed: {
    ...mapState('common', {
      organisation: '$organisation'
    }),
    ...mapGetters('attributes', [
      'getAttributeApiMessage',
      'getAttributeApiResponseCode'
    ])
  },
  created() {
    this.title = this.modalTitle
  },
  methods: {
    showModal(modalId) {
      this.$bvModal.show(modalId)
    },
    hideModal(modalId) {
      this.$bvModal.hide(modalId)
    },
    showModalAndResetState() {
      this.resetState()
      this.showModal(this.modalId)
    },
    selectAttribute(attribute) {
      this.attribute = JSON.parse(
        JSON.stringify(attribute || this.attribute)
      )
    },
    showDeleteModal(attribute) {
      this.selectAttribute(attribute)
      this.showModal(this.deleteAttributesModalId)
    },
    resetState() {
      this.attribute.id = null
      this.attribute.name = null
      this.attribute.color = '#61bd4f'
      this.title = 'Add new attributes'
    },
    addNewAttribute() {
      this.resetState()
      this.showModal(this.upsertAttributesModalId)
    },
    showUpsertAttributeModal(attribute) {
      this.title = this.modalTitle
      this.selectAttribute(attribute)
      this.hideModal(this.modalId)
      this.showModal(this.upsertAttributesModalId)
    },
    async toggleAttribute(attribute) {
      attribute.selected = !attribute.selected
      this.$emit('update-selected-attributes', attribute)
      // eslint-disable-next-line no-unused-vars
      const [_, orgName, orgPath] = this.organisation.split('/')
      const payload = {
        attribute_id: attribute.id,
        object_id: this.selectedValue.id,
        org: orgName,
        org_path: `/${orgPath}`,
        selected: attribute.selected
      }
      await this.updateAttributeAssociation(payload).then(() => {
        this.$emit('refresh-data')
        this.updateAlert(
          this.getAttributeApiResponseCode === 200
            ? 'success'
            : 'danger',
          this.getAttributeApiMessage
        )
      })
    },
    showAlert(payload) {
      this.updateAlert(payload.variant, payload.text)
    },
    updateAlert(variant, text) {
      this.$emit('updateAlert', { variant, text })
    }
  }
}
</script>

<style lang="scss" scoped>
.attributes-container {
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow-y: auto;
  .attribute-container {
    display: grid;
    grid-template-columns: 78% 10% 10%;
    gap: 2%;
  }
  .attribute {
    padding: 5px;
    color: white !important;
    border-radius: 3px;
    cursor: pointer;
    font-weight: 700;
    margin: 0 0 4px;
    min-height: 20px;
    padding: 6px 12px;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    &:hover {
      opacity: 0.9;
    }
    .selected::after {
      content: '\2713';
      display: inline-block;
      color: #ffffff;
      padding: 0 6px 0 0;
    }
  }
}
</style>
